@media screen and (max-width: 400px) {
  #about .overlay-image {
    left: 39%;
  }
  #about .img-responsive {
    margin-left: 36px;
  }
}

@media screen and (max-width: 370px) {
  #about h2 {
    margin-left: -1rem;
  }
}